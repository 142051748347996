@font-face {
  font-family: "Circular";
  src: url("/assets/fonts/CircularXXWeb-Book.woff"), url("/assets/fonts/CircularXXWeb-Book.woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {

  color:black;
  // font-family: "Unica77LL-Regular";
  // font-family: "DiatypePre-Medium";
  // font-family: "UniversLTStd";
  // font-family: "TTNorms-Medium";
  // font-family: "MatterTRIAL-Medium";
  
  font-family: "Circular", Arial, Helvetica, sans-serif;
  // font-family: "ABCOracleBeta-Regular";
  // font-family: "NHaasGroteskTXPro-65Md";
  // font-family: "StyreneB-Regular";
  // font-family: "LBHRadioNormal6-Regular";

  // font-family: "AvenirLTStd-Medium";
  // font-family: "AvenirLTStd-Roman";
  // font-family: "Gotham-Book";
  // font-family: "NeuzeitSLTStd-Book";
  // font-family: "EuclidCircularATrial-Regular";
  
  @include font-size($fs-m-mobile);
  line-height:1;

  // -ms-hyphens: auto;
  // -moz-hyphens: auto;
  // -webkit-hyphens: auto;
  // -khtml-hyphens: auto;
  // hyphens: auto;

  &.page--project {
    overflow:hidden;
    
    .overflow__wrapper {
      position: relative;
      overflow:hidden;
    }
  }
}

::-moz-selection {
  color: black; background: $color-projectinformation;
}

::selection {
  color: black; background: $color-projectinformation;
}

.initialload {
  opacity:0 !important;
}

.preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}

.transition-fade {
  transition: 0.4s;
  opacity: 1;
}

html.is-animating .transition-fade {
  opacity: 0;
}

.desktop {
  display:none
}

.mobile {
  display:inline-block
}

.overflowhidden {
  overflow-y:hidden !important;
  
  #swup, .overflow__wrapper {
    overflow-y:hidden !important;
  }
}

sup {
  vertical-align: super;
 font-size:60%;
}

em {
  font-style:italic;
}

#grid {
  position:fixed;
  width:100vw;
  height:100vh;
  pointer-events:none;
  mix-blend-mode: multiply;
  opacity:0.5;
  z-index:100000;

  img {
    width: 100%;
  }
}

a {
  color:black;
}

body.hasHover {

  a {
    &:hover {
      font-style:italic !important
    }
  }

  #header {
    .header__menutoggle {
      .header__menutoggle__item {
        &:hover {
          font-style: italic;
        }
      }
    }
	}

  #project__header {
    .projecttoggle {
      .projecttoggle__item {
        &:hover {
          font-style: italic;
        }	
        &.inactive {
          &:hover {
            font-style: italic;
          }
        }
      }
    }
  }

  .tooltip {
		position: absolute;
		display: block;
		z-index: 2000;
		pointer-events: none;
  }

}

.language__item, .language {

  // display:flex;
  flex-direction: column;
  // padding-top:15px;
  // transition: opacity 0.5s, transform 0.9s;

  // &.desktop {
  //   // display:none;
  //   // pointer-events: none;
  //   position: fixed;
  //   margin-left:90vw;
  //   display:flex;
  //   pointer-events: all;
  // }
  
    position: absolute;
    right:6px;
    display:flex;
    pointer-events: all;

  // &.mobile {
  //   position: absolute;
  //   right:6px;
  //   display:flex;
  //   pointer-events: all;
  // }

  // &.hidden {
  //   // opacity:0;
  //   pointer-events: none;
  //   transform: translateY(-100vh);
  // }

  // &.visible {
  //   // opacity:1;
  //   pointer-events: all;
  //   transform: translateY(0);
  // }

  span {
    flex:1;
    display: block;
    
    &.language--active {
      // display:none;
      font-style: italic;
    }
    
  }

  .divider {

    &:last-child {
      display:none;
    }

  }

}

.page__header {
  display:flex;
  position:fixed;
  top:0;
  width:100%;
	// @include padding-top(3.3rem);
  padding-top:33px;
	// @include padding-top(1.5rem);
  z-index:80;
  background:white;
  
  
  &.hidden {
    opacity:0
  }
  
  &.visible {
    opacity:1
  }
  
  .projectlink__url {
    display: flex;
    flex-direction: row;
    margin-left:0;
    width:100%;
    // padding-top:$site-padding;
    
    .projecttitle__number {
      flex:1;
      width:33.3333%;
      // @include padding-top(0.8rem);
      // @include padding-left($site-padding);
      padding-left:$lateral-page-gaps-text;
    }
    
    .projecttitle__name__wrapper {
      flex:2;
      width:33.3333%; 
      // @include padding-top(0.5rem);
      @include padding-right(0.5rem);
    }
    
    .projectlink {
      @include padding-right(0.5rem);
      width:10%;
      
      &.hidden {
        opacity:0;
        pointer-events: none;
      }
      
      &.visible {
        opacity:1;
        pointer-events: all;
      }
    }
  }
}


@media (min-width: $breakpoint-mobile-to-tablet-hoch) {

  body {
    @include font-size($fs-m-tablet);
  }

  .page__header {
    
    .projectlink__url {
      
      .projecttitle__number {
        flex:1;
      }
      
      .projecttitle__name__wrapper {
        flex:4;
      }
    }
  }

}

@media (min-width: $breakpoint-tablet-hoch-to-tablet-quer) {

  .desktop {
    display:inline-block
  }
  
  .mobile {
    display:none
  }

  .language__item, .language {
    padding-top:30px;
    // width:11vw
    // width:10%

    // position: fixed;
    // margin-left:90vw;

    // &.desktop {
    //   display:flex;
    //   pointer-events: all;
    // }

    // &.mobile {
    //   display:none;
    //   pointer-events: none;
    // }
  }

  .page__header {
    // @include padding-top(0.1rem);
    padding-top:$site-padding;
    // padding-top:0;
    height:$page-header-height-desktop;
    
    .projectlink__url {
      flex-direction: row;
      margin-left:37.5%;
      width:37.5%;
      // padding-top:$site-padding;
      
      .projecttitle__number {
        flex:1;
        width:10%;
        padding-left: 0;
        padding-top: 0;
      }
      
      .projecttitle__name__wrapper {
        flex:2;
        width:20%;
        padding-top: 0;
      }
      
      .projectlink {
        @include padding-right(0.5rem);
        width:10%;
        
        &.hidden {
          opacity:0;
          pointer-events: none;
        }
        
        &.visible {
          opacity:1;
          pointer-events: all;
        }
      }
    }
  }

}


@media (min-width: $breakpoint-tablet-quer-to-desktop) {

  body {
    @include font-size($fs-m-desktop);
  }

  .language__item, .language {
    // width:9.75vw;
  }

  .page__header {
    
    .projectlink__url {
      padding-top:$site-padding;
      flex-direction: row;
      margin-left:50%;
      width:30%;
    }
  }
}


@media (min-width: $breakpoint-desktop-to-xl) {

  .language__item, .language {
    // width:8.5vw;
  }

  .page__header {
    
    .projectlink__url {
      margin-left:50%;
      width:33.3333%;
      
      .projecttitle__number {
        flex:1;
        width:8.5vw;
        padding-left: 0;
      }
      
      .projecttitle__name__wrapper {
        flex:3;
        // width:20%;
      }
    }
  }
}