#mapping__header {

	height:$page-header-height-mobile;

	.projectlink__url {

		&.url--inactive {
			pointer-events:none !important
		}

		&.url--active {
			pointer-events:all !important
		}

		&:hover {
			font-style: normal !important;
		}

	}


	.mapping__sort {
		// padding-top:$site-padding;
		display:none;

		&:hover {
			cursor:pointer;
		}
		
		.sort--active {
			font-style: italic;

			&::after {
				content:""
			}
			
		}
		
	}
}

#mapping {
	width:100vw;
	// min-height:100vh;
	@include padding-top(10rem);
	padding-left:$lateral-page-gaps-mapping;
	padding-right:$lateral-page-gaps-mapping;
	display:flex;
	flex-wrap: wrap;
	pointer-events:all;
	box-sizing: border-box;

	.mapping__item {
		position: relative;
		flex:1;
		flex-shrink:0;
		flex-grow:0;
		flex-basis:33.333%;
		width:33.333%;
		transition: opacity 0.5s;
		@include padding-bottom(1rem);
		box-sizing: border-box;
		padding-left:$grid-gap-mobile;
		padding-right:$grid-gap-mobile;
		
		
		&.hidden {
			opacity:0;
			pointer-events:none;

			.item__wrapper {
	
				.item__img__wrapper {
					  
					.item__link__wrapper {
						pointer-events:none;
					}
				}
			}
		}
		
		&.visible {
			opacity:1;
			pointer-events:all;
		}
		
		.item__wrapper {
			box-sizing: border-box;
			position: relative;

			.item__img__wrapper {
				width:100%;
				// pointer-events: all;
				
				  
				.item__link__wrapper {
					display: block;
					
					&:hover {
						cursor:pointer;
					}

					.mapping__item__link {

						&:hover {
							cursor:pointer;
						}
						
						&.disabled {
							pointer-events:none;
						}
						
						&.enabled {
							pointer-events:all;
						}
					}
				}
			}	
			
			.item__img {
				width:100%;
			}


			.item__text {
				@include font-size($fs-xs-mobile);
				transition: opacity 0.5s;
	
				.item__count {
					@include padding-left(0.1rem);
				}
	
				.item__projectname {
					opacity:0;
					transition: opacity 0.5s;
				
				}
			
			}

			
		}
	}
}

@media (min-width: $breakpoint-mobile-to-tablet-hoch) {

	#mapping__header {

		.mapping__sort {
			display:block;
			position: fixed;
			top:0;
			left:0;
			padding-top:$site-padding;
			padding-left:66.6667%;
		}
	}

	#mapping {
		@include padding-top(9rem);
	
		.mapping__item {
			flex-basis:16.667%;
			width:16.667vw;
			@include padding-bottom(2rem);

			.item__wrapper {
				box-sizing: border-box;
				position: relative;
	
				&:before {
					display: block;
					content: "";
					width: 100%;
					padding-top: (3 / 2) * 100%;
					pointer-events: none;
				}	
				
				.item__img__wrapper {
					width:100%;
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					pointer-events: all;
				}

				.item__text {
					@include font-size($fs-xs-desktop);
				}
			}
		}
	}

  }

@media (min-width: $breakpoint-tablet-hoch-to-tablet-quer) {

	#mapping__header {

		height:$page-header-height-desktop;

		.mapping__sort {
			position:relative;
			padding-left:0;
			padding-top:0;
		}
	}

	#mapping {
		.mapping__item {
			flex-basis:12.5%;
			width:12.5vw;
			// flex-basis:10%;
			// width:10vw;
		}
	}

  }

@media (min-width: $breakpoint-tablet-quer-to-desktop) {

	#mapping__header {
		padding-top:0;

		.mapping__sort {
			padding-top:$site-padding;
		}
	}

	#mapping {

		@include padding-top($page-padding-top-desktop);
	
		.mapping__item {
			flex-basis:10%;
			width:10vw;

			// .item__text {
			// 	@include font-size($fs-xs-desktop);
			// }
		}
	}

  }

@media (min-width: $breakpoint-desktop-to-xl) {

	#mapping {

		.mapping__item {
			flex-basis:8.3333%;
			width:8.3333vw
		}
	}

}