#menu__header {
	width:100vw;
	height:$page-header-height-mobile;
	background:$color-menu;
	position:fixed;
	z-index:95;
	pointer-events: none;
	transition: opacity 0.2s;

	&.hidden {
		opacity:0;
		transition-delay: 0.2s;
	}

	&.visible {
		opacity:1;
	}
}

#menu {
	width:100vw;
	height:100vh;
	height: calc(var(--vh, 1vh) * 100);
	max-height:100vh;
	@include padding-top($site-padding);
	background:$color-menu;
	position:fixed;
	top:0;
	left:0;
	z-index:90;
	transition: transform 0.4s, opacity 0.2s;
	// transition: transform 1s;
	display:flex;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	opacity:0;


	&.hidden {
		transform:translateY(-110%);
		pointer-events:none;
	}
	
	&.visible {
		transition: transform 0.8s;
		transform:translateY(0);
		pointer-events:all;
	}

	.menu--close {
		position: fixed;
		top:0;
		right:0;
		@include padding-right($site-padding);
		@include padding-top($site-padding);
		width:25px;

		&:hover {
			cursor:pointer;
		}

		svg {
			width:100%;
			stroke:black;
			stroke-width:2;
		}

	}

	.menu__wrapper {
		display:flex;
		flex-direction: column;
		width:100%;
		// @include padding-top(3.5rem);
		padding-top:30px;
		// @include padding-left($site-padding);
		padding-left: $lateral-page-gaps-text;
		line-height:1.1;
		overflow-y: scroll;
		max-height:100vh;

		.menu__item {
			
			&.contact {
				@include padding-bottom(2rem);
				order:1;
			}
			
			// &.language {
			// 	@include padding-bottom(1rem);
			// 	order:2;
			// }

			&.projectlist {
				@include padding-bottom(0.5rem);
				@include font-size($fs-l-mobile);
				display:flex;
				flex-direction: column;
				order:4;
				align-self: flex-start;
				width:100%;
				// max-height:100vh;

				.projectlist__item {
					// display:inline-block;
					display:flex;
					flex-direction: row;
					align-self: flex-start;
					// align-items: flex-start;
					pointer-events: none;
					width:100%;

					&:hover {
						cursor:pointer;
					}

					span {
						pointer-events: all;
						// display: inline-block;
						
						&.item--projectnumber {
							// width:20vw;
							flex:1;
						}
						&.item--projectname {
							// width:20vw;
							flex:2;
						}
					}
				}

			}

			&.pages {
				@include padding-bottom(2rem);
				@include font-size($fs-l-mobile);
				order:3;
			}

		}
	}

}


@media (min-width: $breakpoint-mobile-to-tablet-hoch) {
	#menu {

		.menu__wrapper {
	
			.menu__item {
				&.projectlist {
					// @include font-size($fs-m-tablet);
	
					.projectlist__item {
	
						span {
							
							&.item--projectnumber {
								flex:1;
							}
							&.item--projectname {
								flex:4;
								padding-left:5px;
							}
						}
					}
	
				}

				&.pages {
					// @include font-size($fs-m-tablet);
				}
			}
		}
	}
}


@media (min-width: $breakpoint-tablet-hoch-to-tablet-quer) {
	#menu {

		// height:12vh;
		// height:50vh;
		overflow-y: hidden;
		height:unset;
		overflow:hidden;
		@include padding-bottom(1rem);
		// border: 5px solid red;

		.menu__wrapper {
			flex-direction: row;
			padding-top:0;
			padding-left:0;
			overflow:hidden;
			// width:50%;

		

			.menu__item {

				&.contact {
					padding-bottom:0;
					// padding-left:20%;
					width: 37.5%;
					order:1;
				}
				
				&.projectlist {
					width: 37.5%;
					// height:100vh;
					// overflow-y:scroll;
					padding-bottom:0;
					order:3 !important;
					@include font-size($fs-m-tablet);
					// @include font-size($fs-m-desktop);

					.projectlist__item {
						// display:inline-block !important;
						display:block;
						// width:unset;

						span {
							display:inline-block;
							flex: unset;

							&.item--projectnumber {
								// width:9.5vw !important;
								width:11.5vw !important;
							}

							&.item--projectname {
								padding-left:0;
							}
						}
					}
				}

				&.pages {
					width:12.5%;
					// background-color:yellow;
					order:4 !important;
					@include font-size($fs-m-tablet);
					// @include font-size($fs-m-desktop);

					.legal {
						position: absolute;
						bottom:0;
						@include padding-bottom(1rem);
					}
				}

				&.language {
					order:2;
					width:10%;
				}
			}
		}
	}

  }


@media (min-width: $breakpoint-tablet-hoch-to-tablet-quer) and (max-width: $breakpoint-tablet-quer-to-desktop) {

	#menu {

		.menu__wrapper {

			.menu__item {

				&.contact {
					// @include padding-top(2.5rem);
					padding-top:32px;
					// @include padding-left($site-padding);
					padding-left:$lateral-page-gaps-text;
					width:37.5%;
				}
			}
		}
	}
}


@media (min-width: $breakpoint-tablet-quer-to-desktop) {

	#menu {

		.menu__wrapper {
			.menu__item {

				&.contact {
					// padding-bottom:0;
					padding-left:20%;
					width: 30%;
					// order:1;
				}

				&.projectlist {
					width: 30%;
					@include font-size($fs-m-desktop);

					.projectlist__item {
						span {
							&.item--projectnumber {
								width:10vw !important;
								// width:11.5vw !important;
							
							}
						}
					}
				}

				&.pages {
					@include font-size($fs-m-desktop);
				}
			}
		}
	}
}


@media (min-width: $breakpoint-desktop-to-xl) {
	#menu {

		.menu__wrapper {

			.menu__item {

				&.contact {
					padding-bottom:0;
					padding-left:16.66666%;
					width: 33.33333%;
					order:1;
				}
				
				&.projectlist {
					width: 33.33333%;
					// height:100vh;
					// overflow-y:scroll;
					// -webkit-overflow-scrolling: touch;
					padding-bottom:0;
					order:3 !important;

					.projectlist__item {
						// display:inline-block !important;
						// width:unset;

						span {
							display:inline-block;
							flex: unset;

							&.item--projectnumber {
								width:8.5vw !important;
							}
						}
					}
				}

				&.pages {
					width:10%;
					order:4 !important;
				}

				&.language {
					order:2;
					width:10%;
				}
			}

		}
	}

  }