.slick-slide {
	width:100vw;
}

.hasHover {

	#project {
		.project__images {
			.images__slideshow {
				.slideshow__nav {

					display:flex !important;

					.nav__item {
						pointer-events:all !important;
					}
					// .nav__item {
					// 	width:unset;
						
					// 	&.nav--next {
					// 		flex:4;

					// 	}
						
					// 	&.nav--prev {
					// 		flex:1;
					// 	}
					// }
				}
			}
		}
	}

	.zoomnotice {
		display:none;
	}
}

.zoomnotice {
	display:flex;
	z-index:10000;
	position: fixed;
	justify-content: center;
	align-items: center;
	top:0;
	left:0;
	width:100vw;
	height: calc(var(--vh, 1vh) * 100);
	background: rgba(255,255,255,0.8);
	opacity:0;
	transition: opacity 0.3s ease-in-out;
	pointer-events:none;

	p {
		width:70%;
		text-align:center
	}
}

#project__header {

	background:transparent !important;
	display:flex;
	flex-direction: column;
	width:100vw;
		
	.projectnav {
		display:none;
		// display:flex;
		padding-left:20%; 
		width:30%;
		flex-direction: column;
		align-items: flex-start;
		
	}
	
	.projectclose { 
		width:25px;
		// background:yellow;
		position: absolute;
		text-align: right;
		display: flex;
		right:0;
		
		a {
			padding-right:6px;
			padding-bottom:8px;
			// background:red;
			width:100%;

			&:hover {
				font-style: normal !important;
			}
		}
	}

	.projecttitle__wrapper {
		width:100%;
		// width:85%;
		display:flex;
		flex-direction: row;
		// flex-direction: column;
		// @include padding-left($site-padding);
		padding-left:$lateral-page-gaps-text;
		// @include padding-top(0.8rem);
		@include padding-bottom(0.7rem);
		
		// &:hover {
		// 	background:lightblue;
		// }
		
		.projecttitle__number {
			flex:1;
		}
		
		.projecttitle__name {
			flex:2;
			@include padding-right(1rem);
			position: relative;
			
			&:hover {

				a {

					font-style:normal !important;
				}

			}

			p {
				width:90%;

			}
			
			
			// .projecttitle__back {
			// 	position:absolute;
			// 	top:0;
				
			// 	&.hidden {
			// 		display:none;
			// 	}
			// }
		}
	}
	

	.projecttoggle {
		position: relative;
		font-style: italic;
		@include padding-top(0.1rem);
		display: flex;
		flex-direction: row;
		@include padding-left(0.3rem);
		
		.projecttoggle__empty {
			flex:1;
		}
		
		.projecttoggle__wrapper {
			display: flex;
			flex:2;
			flex-direction: row;
			align-items: flex-start;
			@include padding-right(1rem);
			@include padding-left(0.2rem);
		
			.projecttoggle__item {
				
				@include padding-left(0.2rem);
				
				&::after {
					content:", "
				}
				
				&:first-child {
					padding-left:0;
				}

				&:last-child {
					&::after {
						content:""
					}
				}
				
				&:hover {
					cursor:pointer;
				}	
		
				&.inactive {
					font-style: normal;

					&:hover {
						cursor:pointer;
					}
				}

				&.hidden {
					opacity:0;
					pointer-events: none;
					visibility: hidden;
				}
		
				&.visible {
					opacity:1;
					pointer-events: all;
					visibility: visible;
				}
			
			}

		}
	}

}

#project {
	width:100vw;
	height:100vh;
	height: calc(var(--vh, 1vh) * 100);
	max-height:100vh;
	overflow: hidden;
	
	.project__layer {
		width:100vw;
		height:100vh;
		height: calc(var(--vh, 1vh) * 100);
		max-height:100vh;
		position: absolute;
		top:0;
		left:0;
		transition: opacity 0.5s, transform 0.5s ease-in-out;

		&.hidden {
			transform:translateX(100%);
			pointer-events: none;
		}
		
		&.visible {
			transform:translateX(0%);
			pointer-events: all;
			transition: opacity 0.5s, transform 0.7s ease-in-out;
		}

		.project__layer__header {
			position:fixed;
			top:0;
			width:100%;
			// height:$project-layer-header-height-mobile;
			z-index:70;
		}

		.project__layer__body {
			
			z-index: 60;
			height:100vh;
			height: calc(var(--vh, 1vh) * 100);
			overflow-x:hidden;
			overflow-y:scroll;
			-webkit-overflow-scrolling: touch;
			position: absolute;
			top:0;
			box-sizing: border-box;

			.information__description, .overview__thumbnail__wrapper {
				@include padding-top($page-padding-top);
			}
		}
	}

	.project__images {
		z-index: 50;
		display:flex;
		flex-direction: column;

		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none; /* Non-prefixed version, currently
		supported by Chrome, Opera and Firefox */

		&.nopointerevents {
			pointer-events: none !important;
			
			.images__slideshow, .slideshow__gallery {
				pointer-events: none !important;

			}
		}



		.images__slideshow {

			// padding-top:150px;
			// @include padding-top(14rem);
			// @include padding-top(8.5rem);
			// width:100vw;
			height:100vh;
			height: calc(var(--vh, 1vh) * 100);
			// max-height:100vh;
			display:flex;
			flex-direction: column;
			// box-sizing: border-box;
			// align-items: stretch; /* align items in Cross Axis */
    		// align-content: stretch;
			padding-top:0;
			// background:blue;


			.zoomImg {
				transition: opacity 0.3s ease-in-out;
				transition-delay:100ms;

				&:hover {
					transition-duration: 170ms;
				}
			}
				

			&:hover {
				cursor:none;
			}
			
			.slideshow__nav {
				@include margin-top(4rem);
				z-index:55;
				opacity:0.5;
				position:fixed;
				top:0;
				left:0;
				width:100vw;
				height:100vh;
				height: calc(var(--vh, 1vh) * 100);
				pointer-events:none;
				display:none;
				// display:flex;
				flex-direction: row;
				
				.nav__item {
					// pointer-events:all;
					// width:100%;
					width:25vw;
					
					&.nav--next {
						display:none;
						pointer-events:none;
						// background:yellow;
						// flex:4;
						// align-self:flex-end;
						margin-left:auto;
					}
					
					&.nav--prev {
						// background:blue;
						pointer-events:none;
						// pointer-events:all;
						// flex:1;
						// width:25vw;
					}
				}
			}

			.slideshow__gallery {
				height:100%;
				max-height:90vh;
				// height:50vh;
				position: relative;
				// height:100vh;
				// width:100%;
				flex: 1;
				flex-grow:0;
				flex-shrink:0;
				display:flex;
				box-sizing:content-box;
				display: block;
				
				.slideshow__item {
					// height:85vh;
					// max-height:80vh;
					position: relative;
					// height:86vh;
					// width:100%;
					flex:1;
					// box-sizing: border-box;
					object-fit: contain;
					display:flex;
					flex-grow:0;
					flex-shrink:0;
					// transition: opacity 0.2s ease-in-out;
					transition: opacity 0.5s ease-in-out;

					&.hidden {
						opacity:0;
					}
					
					&.visible {
						opacity:1;
					}
					
					.gallery__img {
						// display:flex;
						// padding-left:0.2%;
						padding-right:4.5px;
						padding-left:$lateral-page-gaps-other;
						// max-width:100%;
						width:100%;
						// height:100%;
						// height:calc(100vh - 8.4rem);
						// max-height:calc(100vh - 8.4rem);
						max-height:calc(100vh - 8.1rem);
						// height:auto;
						height:calc(100vh - 8.1rem);
						height: calc(var(--vh, 1vh) * 100 - 8.1rem);
						max-width:100%;
						// flex:1;
						// max-height:86vh;
						// max-height:50%;
						object-fit: contain;
						// position: absolute;
						top:0;
						left:0;
						// align-self: flex-start;
						// transition: opacity 0.2s;
						transition: opacity 0.5s;
						box-sizing:border-box;

						&.portrait {
							object-position: top left;
							// object-position: bottom left;
						}

						&.landscape, &.square {
							object-position: top left;
							// object-position: center left;
							@include padding-bottom(2rem);
						}

						&.hidden {
							opacity: 0;
							pointer-events: none;
						}

						&.visible {
							opacity: 1;
							pointer-events: all;
						}
					}

				}
			}
			
			.slideshow__counter {
				// align-items: flex-end;
				// margin-top:auto;
				position: absolute;
				bottom:0;
				z-index:100;
				display: flex;
				width:100%;
				margin-top:auto;
				align-self: flex-end;
				align-items: flex-start;
				flex-direction: column-reverse;
				@include padding-bottom(0.2rem);
				padding-left:$lateral-page-gaps-other;
				background:white;
				box-sizing: border-box;
				// height:20px;
				flex-grow:0;
				flex-shrink:0;

				.counter__wrapper {
					display:flex;
					flex-direction: row;
					width:100%;

					.counter__item {
						flex-basis:50%;
						flex-grow: 0;
						flex-shrink: 0;
						
						&.counter--current {
							flex:1;
							padding-left:0.2%;
						}
						
						&.counter--total {
							flex:2;
							padding-left:0.2%;
						}
					}
				}
				
				.img__caption {
					flex-grow: 0;
					flex-shrink: 0;
					@include font-size ($fs-s-mobile);
				}
			}

		}
	}


	
	.project__information {
		background:$color-projectinformation;
		display: flex;
		flex-direction: column;
		line-height:1.15;
		position: relative;
		z-index: 70;
		padding-left: 5px;

		.project__layer__header {
			background:$color-projectinformation;
		}

		a, a:visited, a:active {
			// text-decoration: underline;
			font-style: italic;

			// &:hover {
			// 	font-style:normal !important;
			// }
		}
		
		.project__layer__body {
		
			.information__meta {
				// @include padding-bottom(3rem);
				display: flex;
				flex-direction: column;
				@include padding-top(6rem);
				width:85%;
				// flex-flow: wrap;
				

				.meta__wrapper {
					display: flex;
					// flex-basis:50%;
					width:100%;
					flex-direction: column;
					@include padding-bottom(0.9rem);
					
					span {
						@include padding-right(1rem);
						
						&:first-child {
							@include font-size($fs-s-mobile);
						}
						
					}

				}
			}

			.information__description {
				@include font-size($fs-l-mobile);
				@include padding-right(0.5rem);
				@include padding-bottom(1rem);
				position: relative;
				// margin-bottom:30px;

				&.readmore {
					// height:60vh;
					overflow:hidden;
				}

				&.autoheight {
					height:auto !important;
				}
				
				.readmore__wrapper {
					position: absolute;
					bottom:0;
					display: flex;
					width:100%;
					flex-direction: column;
					// height:80px;
					transition: opacity 0.5s ease-in-out;
					height:150px;

					&.visible {
						opacity:1;
						pointer-events: all;
					}

					&.hidden {
						opacity:0;
						pointer-events: none;
					}

					&:hover {
						cursor:pointer;
					}
					
					.readmore__gradient {
						height:80px;
						width:100%;
						align-self: flex-end;
						margin-top:auto;
						background: linear-gradient(to bottom, rgba(149, 165, 155,0) 0%,rgba(149, 165, 155,1) 100%);
						// background:red;

					}
					
					// .readmore__button {
					// 	background:rgba(149, 165, 155,1);
					// 	font-size:$fs-m-desktop;
					// 	font-style: italic;
					// 	// align-self: flex-end;
					// 	// padding-top:20px
					// }
					// background:red;
				}

				
				// p {

				// 	&:last-child {
				// 		@include padding-bottom(9rem);
				// 	}

				// }
			}

		}
		
		
	}

	.project__overview {
		z-index: 60;
		background:$color-projectoverview;
		@include padding-top($page-padding-top);

		.project__layer__header {
			background:$color-projectoverview;
		}

		.project__layer__body {
		
			.overview__thumbnail__wrapper {
				width:100%;
				display:flex;
				flex-wrap: wrap;
				padding-left:$lateral-page-gaps-mapping;
				padding-right:$lateral-page-gaps-mapping;
				box-sizing:border-box;

				.overview__thumbnail {
					width:33.3333%;
					transition: opacity 0.5s;
					@include padding-bottom(1rem);

					.item__wrapper {
						padding-right:$grid-gap-mobile;
						padding-left:$grid-gap-mobile;
						
						.item__img {
							width:100%;

							&:hover {
								cursor:pointer;
							}
						}
					}

					.item__img__text {
						@include font-size($fs-s-mobile);
					}
				}

			}

		}

	}

}


@media (min-width: $breakpoint-mobile-to-tablet-hoch) {

	#project__header {

		.projecttitle__wrapper {
			.projecttitle__name {
				flex:4;
			}
		}

		.projecttoggle {
			@include padding-top(0.2rem);

			.projecttoggle__wrapper {
				flex:4;
			}
		}
	}

	#project {

		.project__images {
			.images__slideshow {
				// @include padding-top(10rem);
				// @include padding-top(7rem);

				.slideshow__nav {
					
					.nav__item {
						width:25vw;
					}
				}

				.slideshow__gallery {

					.slideshow__item {
						
						.gallery__img {
							max-height:calc(100vh - 8.5rem);
							height:calc(100vh - 8.5rem);
							height: calc(var(--vh, 1vh) * 100 - 8.5rem);
							// &.portrait {
							// 	object-position: bottom left;
							// }
							
							&.landscape, &.square {
								// object-position: top left;
								padding-bottom:0;
							}
						}
					}
				}

				.slideshow__counter {

					.counter__wrapper {
						
						.counter__item {
							&.counter--current {
								flex:1;
							}
							
							&.counter--total {
								flex:4;
							}
						}
					}

					.img__caption {
						@include font-size ($fs-s-tablet);
					}
				}
			}
		}

		.project__information {
			.project__layer__body {

				.information__meta {
					@include padding-top(5rem);
					// flex-direction: row;
					// display: grid; 
					// grid-template-rows: repeat(5,auto); 
					// grid-template-columns: 1fr 1fr; 
					// grid-auto-flow: column; 
					columns: 2;
					flex-wrap:wrap;
					width:100%;

					.meta__wrapper {
						width:48%;

						span {
							&:first-child {
								@include font-size($fs-s-tablet);
							}
						}
					}

				}

				.information__description {
					@include padding-top(9rem);
					@include font-size($fs-l-tablet);

					&.readmore {
						// height:50vh;
					}
						
					// p {
					// 	&:last-child {
					// 		@include padding-bottom(6rem);
					// 	}
					// }
				}
			}
		}

		.project__overview {
			.project__layer__body {
				.overview__thumbnail__wrapper {

					@include padding-top(9rem);

					.overview__thumbnail {
						
						width:20%;

						.item__img__text {
							@include font-size($fs-s-tablet);
						}
					}
				}
			}
		}
	}
}


@media (min-width: $breakpoint-tablet-hoch-to-tablet-quer) {


	#project__header {
		flex-direction: row;

		.projectclose { 
			display:none !important;
			// display:none;
			pointer-events: none;
		}

			
		// .projectnav {
		// 	display:flex;
		// 	// width:20%;
		// }

		.projecttitle__wrapper {
			width:37.5%;
			padding-left:37.5%;
			// padding-left:0;
			padding-top:0;
			padding-bottom:0;
			flex-direction: row;

			.projecttitle__number {
				padding-left: $lateral-page-gaps-other;
			}

			.projecttitle__name {
				flex:2;
				padding-right:0;
					
				a {
					display: inline-block;
					width: 80%;
				}

				// p {
				// 	width:90%;
				// }
			}
		}
		
		.projecttoggle {
			width:10%;
			padding-left:0;
			flex-direction: column;
			padding-top:0;

			.projecttoggle__wrapper {
				flex-direction: column;

				.projecttoggle__item {

					padding-left:0;
		
					&::after {
						content:""
					}

				}
			}
		}
	}

	#project {


		.project__layer {

			&.hidden {
				transform:translateX(100%);
			}
			
			&.visible {
				transform:translateX(37.5%);
			}

			.project__layer__header {
				height:$page-header-height-desktop;
				// height:52px;
			}

			.project__layer__body {
				// @include padding-top($page-padding-top-desktop);
				@include padding-top(7rem);
			

				.information__description, .information__meta, .overview__thumbnail__wrapper {
					padding-top:0;
				}
			}
		}

		.project__images {
			.images__slideshow {
				@include padding-top(5.8rem);
				// @include padding-top(7rem);



				.slideshow__nav {
					.nav__item {
						
						&.nav--next {
							width:75vw;
							
						}
						
						&.nav--prev {
							width:25vw;
							pointer-events: all;
							// flex:1;
						}
					}
				}

				.slideshow__gallery {

					.slideshow__item {
						
						.gallery__img {
							// height:calc(100vh - 7.3rem);
							// max-height:calc(100vh - 7.3rem);
						}
					}
				}

				.slideshow__counter {
					// flex-direction: row;
	
					.counter__wrapper {
						width:25%;

						.counter__item {
							&.counter--current {
								flex:1;
							}
							
							&.counter--total {
								flex:1;
							}
						}
					}
				}

				.img__caption {
					padding-left:20%;
					flex-basis:39%;
					columns:2;
				}
			}
		}

		.project__information {

			padding-left:0;
			
			.project__layer__body {
			
				.information__meta {
					// @include padding-bottom(1rem);
					padding-bottom: 0;
					width:50.6%;
					padding-left:12.5%;
				}

				.information__description {
					padding-left:12.5%;
					width:49%;
					margin-bottom:50px;

					// &.readmore {
					// 	height:55vh;
					// }

					// width:39%;
					// padding-right:0;
					// padding-left:$lateral-page-gaps-text;
					// width:60%;

					p {
						&:last-child {
							@include padding-bottom(3rem);
						}
					}
				}
			}
		}

		.project__overview {
			@include padding-top($page-padding-top-desktop);

				.project__layer__body {
				
					.overview__thumbnail__wrapper {
						// width:50vw;
						width:62.5vw;

						.overview__thumbnail {
							width:20%;
						}
					}
				}
		}
	}

}



@media (min-width: $breakpoint-tablet-quer-to-desktop) {


	#project__header {

		.projectnav {
			display:flex;
		}

		.projecttitle__wrapper {
			width:30%;
			padding-left:0;
		}

	}

	#project {

		

		.project__layer {

			// &.hidden {
			// 	transform:translateX(100%);
			// }
			
			&.visible {
				transform:translateX(50%);
			}
		}

		.project__information {
			.project__layer__body {

				.information__meta {
					// @include padding-bottom(1rem);
					padding-bottom:0;
					width:40%;
					padding-left:10%;

					.meta__wrapper {

						span {
							&:first-child {
								@include font-size($fs-s-desktop);
							}
						}
					}
				}

				.information__description {
					@include font-size($fs-l-desktop);
					padding-left:10%;
					width:39%;
				}
			}
		}

		.project__images {
			.images__slideshow {
				@include padding-top(7rem);

				.slideshow__gallery {
					
					.slideshow__item {
						.gallery__img {
							max-height:calc(100vh - 8.4rem);
							height:calc(100vh - 8.4rem);
							height: calc(var(--vh, 1vh) * 100 - 8.4rem);
						}
					}
				}

				.slideshow__counter {
	
					.counter__wrapper {
						width:40%;
					}

					.img__caption {
						@include font-size ($fs-s-desktop);
					}
				}
			}
		}

		.project__overview {

				.project__layer__body {
				
					.overview__thumbnail__wrapper {
						width:50vw;

						.overview__thumbnail {
							
							.item__img__text {
								@include font-size($fs-s-desktop);
							}
						}

					}
				}
		}
	}

}

@media (min-width: $breakpoint-desktop-to-xl) {

	#project__header {

		.projectnav {
			padding-left:16.6666%;
			width:33.3333%; 
		}

		.projecttitle__wrapper {
			width:33.3333%;

			.projecttitle__name {
				flex:3;
			}
		}
	}

	#project {

		.project__images {
			.images__slideshow {
				.slideshow__counter {
					.counter__wrapper {
						width:33.333%;
					}
				}
			}
		}

		.project__information {
			
			.project__layer__body {
			
				.information__meta {
					width:33.333%;
					// width:24.9999%;
					padding-left:8.33333%;
				}

				.information__description {
					width:39.3%;
					padding-left:8.33333%;
				}
			}
		}

		.project__overview {

			.project__layer__body {
			
				.overview__thumbnail__wrapper {

					.overview__thumbnail {
						width:8.3vw;
					}
				}
			}
		}
	}	

}