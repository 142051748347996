#header {
	// @include padding($site-padding);
	padding-top:$site-padding;
	padding-bottom:$site-padding;
	padding-left:$lateral-page-gaps-text;
	padding-right:$lateral-page-gaps-text;
	width:100vw;
	box-sizing: border-box;
	z-index:100;
	position: fixed;
	top:0;
	pointer-events:none;
		
		
	.header__sitetitle {
		pointer-events:none;
		transition: opacity 0.5s;

		.header__sitetitle__item {
			pointer-events:all;
			display: inline-block;

			&.item--1 {
				flex:2;
			}
			
			&.item--2 {
				
				flex:1;
			}

			a {

				&:hover {
					font-style: normal !important;
				}
			}
		}
	

	}

	.header__menutoggle {
		position: absolute;
		top:0;
		right:0;
		// @include padding-right(0.5rem);
		padding-right:6px;
		padding-top:$site-padding;
		transition: opacity 0.5s;
		pointer-events:all;
		text-align: right;
		
		&.hidden {
			opacity:0;
		}
		
		&.visible {
			opacity:1;
		}

		.header__menutoggle__item {

			padding-left:15px;

			&:hover {
				cursor: pointer;
				// font-style: italic;
			}
		}
	}

}