$breakpoint-mobile-to-tablet-hoch: 700px;
$breakpoint-tablet-hoch-to-tablet-quer: 900px;
$breakpoint-tablet-quer-to-desktop: 1400px;
$breakpoint-desktop-to-xl: 1700px;

$site-padding:4px;
$page-padding-top: 14rem;
$page-padding-top-desktop: 6rem;

$color-menu: rgb(236, 236, 236);

$color-projectinformation: rgb(241, 241, 241);
$color-projectoverview: rgb(210, 210, 210);

$fs-xs-mobile: 0.5rem;
$fs-s-mobile: 0.87rem;
$fs-m-mobile: 0.99rem;
$fs-l-mobile: 1.19rem;

// $fs-s-tablet: 0.7rem;
// $fs-m-tablet: 0.91rem;
// $fs-l-tablet: 1.3rem;
$fs-s-tablet: 0.6rem;
$fs-m-tablet: 0.81rem;
$fs-l-tablet: 1.2rem;

// $fs-xs-desktop: 0.6rem;
$fs-xs-desktop: 0.55rem;
// $fs-s-desktop: 0.8rem;
$fs-s-desktop: 0.75rem;
// $fs-m-desktop: 1.05rem;
$fs-m-desktop: 0.95rem;
// $fs-l-desktop: 1.6rem;
$fs-l-desktop: 1.35rem;
$fs-xl-desktop: 1.75rem;

// $fs-xs-desktopxl: 0.6rem;
// $fs-s-desktopxl: 0.8rem;
// $fs-m-desktopxl: 1.05rem;
// $fs-l-desktopxl: 1.6rem;

$grid-gap-mobile: 1.5px;
$lateral-page-gaps-mapping: 1.5px;
$lateral-page-gaps-other: 3.5px;
$lateral-page-gaps-text: 5px;

$project-layer-header-height-mobile: 80px;
$page-header-height-mobile: 20px;
$page-header-height-desktop: 58px;