#information__header {
	height:$page-header-height-mobile;

	.page__title {
		// @include padding-left($site-padding);	
		padding-left:$lateral-page-gaps-text;

		&:hover {
			a {
				font-style: normal !important;
			}
		}
	}
}


#information {
	@include padding-top($page-padding-top);
	// @include padding-bottom(3rem);
	width:100vw;
	display:flex;
	flex-direction: column;
	flex-wrap: wrap;
	line-height:1.15;

	a, a:visited, a:active {
		font-style: italic;
	}

	.information__cv__wapper {
		display: flex;
		flex-direction: column;
	}
	
	.information__item {
		display: block;
		// @include padding-left($site-padding);	
		padding-left:$lateral-page-gaps-text;
		padding-bottom:0;
		
		&.information__text {
			@include font-size($fs-l-mobile);
			// @include padding-left($site-padding);	
			padding-left: 5px;
			@include padding-bottom(9rem);	
			
			p {
				@include padding-right(0.3rem);
				// padding-bottom:0;
				text-indent: 2rem;

				&:first-child {
					text-indent: 0;
				}
			}
		}
		
		&.information__cv {
			// width:40%;
			display:flex;
			flex-direction: column;
			
			&.cv--right {
				@include padding-bottom(1rem);	
			}
			
			.cv__item {
				flex:1;
				display:flex;
				flex-direction: column;
				@include padding-bottom(4rem);	
				// @include padding-left($site-padding);	
				
				.cv__item__header {
					@include padding-bottom(1rem);
				}
				
				.cv__item__text {
					
					p {
						@include padding-right(1rem);	
						text-indent: 2.5rem;
						
						&:first-child {
							text-indent: 0 !important;
						}
					}
					
				}
			}
		}
		
		&.information__more {
			@include font-size($fs-s-mobile);
			columns:2;
			display:flex;
			flex-direction: column;
			flex-wrap: wrap;
			
			.more__item {
				@include padding-top(0.5rem);	

				.more__item__header {
					@include padding-bottom(0.6rem);
				}
				
				.more__item__text {
					@include padding-bottom(1rem);

					p, li {
						@include padding-right(3rem);	
					}

				}
			}
			
		}

	}
}

@media only screen and (min-width: $breakpoint-mobile-to-tablet-hoch) {

	#information {
		flex-direction: row;
		@include padding-top(10rem);

		.information__item {

			&.information__text {
				@include font-size($fs-xl-desktop);
				@include padding-bottom(6rem);	
			}

			&.information__cv {
				width:83%;
				// width:49.5%;
				// width:39.5%;
				flex-direction: column;
				@include padding-bottom(3rem);	
				
				&.cv--right {
					@include padding-bottom(5rem);	

				}

				.cv__item {
					// @include padding-bottom(2rem);	
					padding-bottom:0;
					flex-basis:49%;
					flex-grow: 0;
					flex-shrink: 0;
				}
			}

			&.information__more {
				@include font-size($fs-s-tablet);
				width:49%;
				// background:red;
				
				// &:last-child {
				// 	padding-right:20%;
				// }
			}
		}
	}

}

@media only screen and (min-width: $breakpoint-tablet-hoch-to-tablet-quer) {

	#information__header {

		height:$page-header-height-desktop;

		.page__title {
			padding-left:75%;
			width:25%;
		}
	}

	#information {
		@include padding-top($page-padding-top-desktop);

		.information__cv__wapper {
			width:50%;
		}

		.information__item {
			padding-left:0;
			padding-bottom:0;
			
			&.information__text {
				width:75%;
				// padding-bottom:0;
				padding-bottom:4rem;
				// padding-right:40%;
				
				p {
					text-indent: 3rem;
				}
			}
			
			&.information__cv {
				width:100%;
				padding-bottom:0;
				
				&.cv--right {

					padding-bottom:0;
				}

				.cv__item:first-child {
					padding-left: $lateral-page-gaps-text;
					padding-bottom:3rem;
				}
			}
			
			&.information__more {
				width:25%;
				padding-bottom:0;
			}
		}
	}

}

@media only screen and (min-width: $breakpoint-tablet-quer-to-desktop) {

	#information__header {

		.page__title {
			padding-left:79.8%;
			width:20%;
		}
	}

	#information {

		.information__cv__wapper {
			flex-direction: row;
			width: 60%;
		}

		.information__item {

			&.information__text {
				width:80%;
			}

			&.information__more {
				width:20%;
				@include font-size($fs-s-desktop);
			}

		}
	}
}




@media (min-width: $breakpoint-desktop-to-xl) {

	#information__header {

		.page__title {
			padding-left:83.3333%;
		}
	}

	#information {

		.information__cv__wapper {
			width: 50%;
			// padding-left: 16.6667%;
		}

		.information__item {
			
			&.information__text {
				width:50%;
				padding-right:50%;
				
			}

			&.information__cv {
				width:49.9999%;
				// width:24.9999%;
			}
			
			&.information__more {
				width:16.6666%;
			}
		}
	}
}